<template>
  <v-list-item link @click.native="saveData" :disabled="disabled" :title="alt">
    <v-list-item-title>{{ title }}</v-list-item-title>
  </v-list-item>
</template>

<script>
export default {
  name: "PersistDataButton",
  props: {
    title: {
      type: String,
      required: true,
    },
    data: {
      type: String,
      required: true,
    },
    storageKey: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    alt: {
      type: String,
      default: "",
    },
  },
  data() {
    return {};
  },
  setup(props, { emit }) {
    const emitPersisted = () => {
      emit("dataPersisted");
    };

    return {
      emitPersisted,
    };
  },
  methods: {
    saveData() {
      if (this.disabled) {
        return;
      }

      localStorage.setItem(this.storageKey, this.data);

      this.emitPersisted();
    },
  },
};
</script>
