var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"outlined":""}},[_c('v-card-title',{staticClass:"align-start"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_vm._v("Performances par article")])],1),_c('dot-menu',{attrs:{"isLoading":_vm.loading,"csvButton":{
        csvData: _vm.csvData,
        filename: ("perf-par-article-" + (this.startDate) + "_" + (this.endDate) + ".csv"),
      }},on:{"exportCsv":_vm.exportCSV},scopedSlots:_vm._u([{key:"extraButtons",fn:function(){return [('Keep this element as usage example' === '')?_c('persist-data-button',{attrs:{"title":"Prévision inventaire sur la sélection","data":_vm.articleIds.join(','),"disabled":_vm.loading || _vm.articleIds.length <= 0 || _vm.articleIds.length > 200,"alt":"La sélection doit être comprise entre 1 et 200 articles","storageKey":"audience/perf-per-article/article-list"},on:{"dataPersisted":_vm.goToForecast}}):_vm._e()]},proxy:true}])})],1),_c('v-card-title',{staticClass:"align-start"},[_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Recherche (par : Site, Redac, Catégorie, Titre, URL, ...)","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-select',{attrs:{"outlined":"","clearable":"","items":['site', 'category', 'redac', 'title', 'url'],"menu-props":{ maxHeight: '200', zIndex: 13 },"label":"Dans la colonne"},model:{value:(_vm.search_column),callback:function ($$v) {_vm.search_column=$$v},expression:"search_column"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('table-stats',{attrs:{"data":_vm.stats}})],1)],1)],1),_c('v-card-text',[_c('v-data-table',{attrs:{"page":_vm.options.page,"pageCount":_vm.numberOfPages,"headers":_vm.headers,"items":_vm.items,"options":_vm.options,"server-items-length":_vm.total,"loading":_vm.loading,"sort-by":_vm.sortBy,"sort-desc":true,"search":_vm.search,"footer-props":{
        'items-per-page-options': [10, 25, 50],
      },"items-per-page":_vm.options.itemsPerPage},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.adn_post_id",fn:function(ref){
      var item = ref.item;
return [(item.adn_post_id)?_c('v-btn',{staticClass:"ma-2",attrs:{"text":"","icon":""},nativeOn:{"click":function($event){_vm.dialogModel = {
              dialog: !_vm.dialogModel.dialog,
              id: item.adn_post_id,
            }}}},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiChartLine))])],1):_vm._e()]}},{key:"item.title",fn:function(ref){
            var item = ref.item;
return [_c('div',{staticClass:"truncateCell",attrs:{"title":item.title}},[_vm._v(" "+_vm._s(item.title)+" ")])]}},{key:"item.adn_post_url_path_identifier",fn:function(ref){
            var item = ref.item;
return [_c('div',{staticClass:"truncateCell",attrs:{"title":item.adn_post_url_path_identifier}},[_c('a',{attrs:{"href":item.url,"target":"_blank"}},[_vm._v(_vm._s(item.adn_post_url_path_identifier))])])]}},{key:"item.meta_description",fn:function(ref){
            var item = ref.item;
return [_c('div',{staticClass:"truncateCell",attrs:{"title":item.meta_description}},[_vm._v(" "+_vm._s(item.meta_description)+" ")])]}}])})],1),_c('v-dialog',{attrs:{"width":"70vw"},model:{value:(_vm.dialogModel.dialog),callback:function ($$v) {_vm.$set(_vm.dialogModel, "dialog", $$v)},expression:"dialogModel.dialog"}},[_c('post-performances-modal',{attrs:{"dialogOpen":_vm.dialogModel.dialog,"id":_vm.dialogModel.id,"startDate":this.startDate,"endDate":this.endDate,"periodType":this.periodType}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }