<template>
  <div>
    <vue-apex-charts
      :type="type"
      :height="options.chart.height"
      :options="options"
      :color="color"
      :xaxisType="xaxisType"
      :series="series"
      v-if="series.length > 0"
    />
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";

export default {
  props: {
    type: {
      type: String,
      default: "bar",
    },
    legend: {
      type: Boolean,
      default: true,
    },
    series: {
      type: Array,
      default: [],
    },
    color: {
      type: Array,
      default: () => ["#008FFB", "#00E396", "#FEB019", "#FF4560", "#775DD0"],
    },
    xaxisType: {
      type: String,
      default: "text",
    },
    chartOptions: {
      type: Object,
      default: {},
    },
    chartCategories: {
      type: Array,
      default: [],
    },
  },
  created() {
        this.updateOptions();
  },
  methods: {
    updateOptions() {
      this.options = {
        ...this.chartOptions,
        chart: {
          ...(this.chartOptions?.chart ?? {}),
          height: this.chartOptions?.chart?.height ?? '500px',
        },
        legend: {
          show: this.legend,
        },
        colors: this.color,
        xaxis: {
          ...(this.chartOptions?.xaxis ?? {}),
          categories: this.chartCategories,
          type: this.xaxisType,
          ...(this.chartOptions?.xaxis?.labels ?? {
            labels: {
              rotate: 0,
              hideOverlappingLabels: true,
              trim: true,
            },
          }),
        },
      };
          },
  },
  data() {
    return {
      options: {
        chart:{
          height: '500px',
        }
      },
    };
  },
  watch: {
    chartCategories: {
      handler() {
        this.updateOptions();
      },
    },
    chartOptions: {
      handler() {
        this.updateOptions();
      },
    },
    color: {
      handler() {
        this.updateOptions();
      },
    },
    legend: {
      handler() {
        this.updateOptions();
      },
    },
  },
  components: {
    VueApexCharts,
  },
};
</script>
