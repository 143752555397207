<template>
  <v-card>
    <v-img
      cover
      max-width="100%"
      max-height="200px"
      class="img-cover"
      :src="image"
    >
      <div class="fill-height bottom-gradient"></div>
      <div class="title-container">
        <span class="title text-h4">Performances ({{ this.id }}) </span>
      </div>
    </v-img>

    <v-card-text>
      <v-container v-if="loading" class="text-center">
        <v-progress-circular
          color="primary"
          indeterminate
        ></v-progress-circular>
      </v-container>
      <v-container v-else>
        <v-row>
          <v-col cols="12"
            ><v-list class="mb-2">
              <v-list-item class="d-flex px-0">
                <div class="d-flex align-center flex-grow-1 flex-wrap">
                  <div class="me-auto pe-2">
                    <h4 class="text-h5 font-weight-semibold">Site</h4>
                    <span>{{ site }}</span>
                  </div>
                </div>
              </v-list-item>
              <v-list-item class="d-flex px-0">
                <div class="d-flex align-center flex-grow-1 flex-wrap">
                  <div class="me-auto pe-2">
                    <h4 class="text-h5 font-weight-semibold">Titre</h4>
                    <span>{{ title }}</span>
                  </div>
                </div>
              </v-list-item>
              <v-list-item class="d-flex px-0" v-if="canSeeCaData">
                <div class="d-flex align-center flex-grow-1 flex-wrap">
                  <div class="me-auto pe-2">
                    <h4 class="text-h5 font-weight-semibold">Revenus</h4>
                    <span>{{ ca }}</span>
                  </div>
                </div>
              </v-list-item>
              <v-list-item class="d-flex px-0">
                <div class="d-flex align-center flex-grow-1 flex-wrap">
                  <div class="me-auto pe-2">
                    <h4 class="text-h5 font-weight-semibold">Sessions</h4>
                    <span>{{ sessions }}</span>
                  </div>
                </div>
              </v-list-item>
            </v-list>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="6" v-if="canSeeCaData">
            <v-card class="greeting-card">
              <v-card-title class="d-flex justify-space-between"
                >Revenus</v-card-title
              >
              <generic-graph
                type="line"
                :series="revenueSeries"
                :chartOptions="revenuesChartOptions"
                :chartCategories="categories"
                xaxisType="datetime"
                :color="['#008FFB']"
                :legend="false"
              ></generic-graph>
            </v-card>
          </v-col>
          <v-col cols="12" :md="canSeeCaData ? 6 : 12">
            <v-card class="greeting-card">
              <v-card-title class="d-flex justify-space-between"
                >Sessions</v-card-title
              >
              <generic-graph
                type="line"
                :series="sessionsSeries"
                :chartOptions="sessionsChartOptions"
                :chartCategories="categories"
                xaxisType="datetime"
                :color="['#008FFB']"
                :legend="false"
              ></generic-graph>
            </v-card>
          </v-col>
        </v-row>
        <v-row v-if="channelValues">
          <v-col cols="12">
            <v-data-table
              :headers="headers"
              :items="channelValues"
              :sort-by="sortBy"
              :sort-desc="true"
              :hide-default-footer="true"
              :disable-pagination="true"
              class="elevation-1"
            >
              <template v-slot:item.ca="{ item }" v-if="canSeeCaData">
                {{ formatNumberToDisplay(item.ca, true) }}
              </template>
              <template v-slot:item.sessions="{ item }">
                {{ formatNumberToDisplay(item.sessions) }}
              </template>
              <template v-slot:item.views="{ item }">
                {{ formatNumberToDisplay(item.views) }}
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import GenericGraph from "@/components/common/charts/GenericGraph";
import axios from "@axios";
import { mdiOpenInNew } from "@mdi/js";
import {
  decodeHtmlEntities,
  formatNumber,
  formatCurrency,
} from "@/utils/formatting";
import { colors } from "@/utils/constants";

const formatThisNumber = (val, opt) => {
  const { seriesIndex, _, w } = opt;
  const groupName = w.config.series[seriesIndex].name;

  return ["Revenus", "CA"].includes(groupName)
    ? formatCurrency(val)
    : formatNumber(val);
};

const yAxisLabels = (formatter) => ({
  formatter: (val) => formatter(val),
  style: {
    fontSize: "12px",
    fontWeight: 400,
  },
});

const yAxis = (formatter) => ({
  min: 0,
  forceNiceScale: true,
  labels: yAxisLabels(formatter),
});

const dataLabels = (formatter) => ({
  enabled: true,
  textAnchor: "middle",
  formatter,
  dropShadow: {
    enabled: true,
  },
});

const tooltip = (formatter) => ({
  show: false,
  shared: true,
  intersect: false,
  y: {
    formatter,
  },
});

const chartOptions = {
  plotOptions: {
    bar: {
      dataLabels: {
        position: "top",
      },
    },
  },
  stroke: {
    curve: "smooth",
  },
  yaxis: [yAxis(formatNumber)],
  dataLabels: dataLabels(formatThisNumber),
  tooltip: tooltip(formatThisNumber),
  fill: {
    opacity: 1,
  },
};

export default {
  name: "PostPerformancesModal",
  components: {
    GenericGraph,
  },
  props: {
    dialogOpen: {
      type: Boolean,
      required: true,
    },
    id: {
      type: Number,
      required: true,
    },
    startDate: {
      type: String,
      required: true,
    },
    endDate: {
      type: String,
      required: true,
    },
    periodType: {
      type: String,
      required: true,
    },
  },
  data() {
    const canSeeCaData =
      this.$can("manage", "audience") || this.$can("manage", "regie_with_ca");

    return {
      loading: false,
      canSeeCaData,
      image: "",
      title: "",
      site: "",
      ca: "",
      sessions: "",
      revenueSeries: [],
      sessionsSeries: [],
      channelValues: [],
      //itemSeries: [],
      sortBy: canSeeCaData ? "ca" : "views",
      headers: [
        {
          text: "Channel group",
          value: "channel",
        },
        ...(canSeeCaData
          ? [
              {
                text: "CA",
                value: "ca",
              },
            ]
          : []),
        {
          text: "Sessions",
          value: "sessions",
        },
        {
          text: "Vues",
          value: "views",
        },
      ],

      // icons
      icons: {
        mdiOpenInNew,
      },

      revenuesChartOptions: {
        ...chartOptions,
        yaxis: [
          {
            ...chartOptions.yaxis[0],
            labels: {
              ...chartOptions.yaxis[0].labels,
              formatter: (val) => formatCurrency(val),
            },
          },
        ],
      },
      sessionsChartOptions: {
        ...chartOptions,
        colors: [colors.sessions],
      },
      //caChartOptions,

      categories: [],
      //itemCategories: [],
    };
  },
  created() {
    this.getData();
  },
  methods: {
    formatNumberToDisplay(number, isCurrency = false) {
      return isCurrency ? formatCurrency(number) : formatNumber(number);
    },
    async getData() {
      if (
        this.startDate &&
        this.startDate.length === 10 &&
        this.endDate &&
        this.endDate.length === 10
      ) {
        this.loading = true;
        const { data } = await axios.get(`/adn-post/revenues/${this.id}`, {
          params: {
            start_date: this.startDate,
            end_date: this.endDate,
            period_type: this.periodType,
          },
        });

        const site = this.getAllSites.find((site) => site.id === data.site_id);
        this.site = site?.name ?? data.site_id;
        this.title = decodeHtmlEntities(data.title);
        if (data?.ca !== undefined) {
          this.ca = formatCurrency(data.ca);
        }
        this.sessions = formatNumber(data.sessions);

        if (data?.ca !== undefined) {
          this.revenueSeries = [
            {
              name: "Revenus",
              data: data.items.map((item) => ({
                x: item.date,
                y: item.ca,
              })),
            },
          ];
        }
        this.sessionsSeries = [
          {
            name: "Sessions",
            data: data.items.map((item) => ({
              x: item.date,
              y: item.sessions,
            })),
          },
        ];

        this.channelValues = data.channels_details;
        this.loading = false;
      }
    },
  },
  computed: {
    getAllSites() {
      return this.$store.getters["common/getSiteList"];
    },
    chartOptions() {
      return {
        plotOptions: {
          bar: {
            dataLabels: {
              position: "top",
            },
          },
        },
        stroke: {
          curve: "smooth",
        },
        yaxis: [
          {
            min: 0,
            forceNiceScale: true,
            labels: {
              formatter: (val) => formatNumber(val),
              style: {
                fontSize: "12px",
                fontWeight: 400,
              },
            },
          },
        ],
        dataLabels: {
          enabled: true,
          textAnchor: "middle",

          formatter: formatThisNumber,
          dropShadow: {
            enabled: true,
          },
        },
        tooltip: {
          show: false,
          shared: true,
          intersect: false,
          y: {
            formatter: formatThisNumber,
          },
        },
        fill: {
          opacity: 1,
        },
      };
    },
  },
  watch: {
    dialogOpen(visible) {
      if (visible) {
        this.getData();
      }
    },
    startDate() {
      this.getData();
    },
    endDate() {
      this.getData();
    },
    periodType: function () {
      this.getData();
    },
  },
};
</script>

<style scoped>
.bottom-gradient {
  background-image: linear-gradient(
    to top,
    rgba(0, 0, 0, 0.7) 0%,
    transparent 72px
  );
}

.title {
  position: absolute;
  bottom: 20px;
  left: 20px;
  font-weight: bold;
  color: white;
}

.repeating-gradient {
  background-image: repeating-linear-gradient(
    -45deg,
    rgba(255, 0, 0, 0.25),
    rgba(255, 0, 0, 0.25) 5px,
    rgba(0, 0, 255, 0.25) 5px,
    rgba(0, 0, 255, 0.25) 10px
  );
}
</style>
