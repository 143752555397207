<template>
  <div>
    <v-row class="d-flex">
      <v-col cols="4">
        <site-groups-filter store="audience" />
      </v-col>
      <v-col cols="3">
        <date-filter store="audience" periodType="past-only"></date-filter>
      </v-col>
      <v-col cols="3">
        <v-select
          outlined
          v-model="periodType"
          :items="periodTypeList"
          :menu-props="{ maxHeight: '400', zIndex: 13 }"
          label="Pour la période"
          item-text="name"
          item-value="id"
        ></v-select>
      </v-col>

      <v-col cols="2" class="d-flex">
        <v-spacer />
        <apply-filters :disabled="isLoading" outlined color="secondary" />
      </v-col>

      <v-col cols="6">
        <sites-filter store="audience" />
      </v-col>
      <v-col cols="6">
        <categories-filter store="audience" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import useAxios from "@/hooks/useAxios";

import ApplyFilters from "@/components/common/filters/ApplyFilters.vue";
import CategoriesFilter from "@/components/common/filters/CategoriesFilter.vue";
import DateFilter from "@/components/common/filters/DateFilter";
import SitesFilter from "@/components/common/filters/SitesFilter";
import SiteGroupsFilter from "@/components/common/filters/SiteGroupsFilter";

import { defaultPeriodType } from "@/components/audience/perf-per-article/utils/constants";

export default {
  name: "Filters",
  components: {
    ApplyFilters,
    CategoriesFilter,
    DateFilter,
    SitesFilter,
    SiteGroupsFilter,
  },
  data() {
    return {
      periodTypeList: [
        { id: defaultPeriodType, name: "Articles vus" },
        { id: "articles_created", name: "Articles créés" },
      ],
    };
  },
  setup() {
    const { isLoading } = useAxios();

    return {
      isLoading,
    };
  },
  methods: {
    validatePeriodType(periodType) {
      const allowedValues = this.periodTypeList.map((entry) => entry.id);

      return allowedValues.includes(periodType)
        ? periodType
        : defaultPeriodType;
    },
  },
  computed: {
    periodType: {
      get() {
        const storedValue = this.$store.getters["audience/getPeriodType"];
        const value = this.validatePeriodType(storedValue);
        if (value !== storedValue) {
          this.$store.dispatch("audience/updatePeriodType", value);
        }

        return value;
      },
      set(e) {
        this.$store.dispatch(
          "audience/updatePeriodType",
          this.validatePeriodType(e)
        );
      },
    },
  },
};
</script>

<style></style>
