<template>
  <v-card outlined>
    <line-chart
      title="Sessions et articles créés"
      :loading="loading"
      :colors="colors"
      :series="series"
      :yAxis="yAxis"
      :xAxis="xAxis"
      :stroke="stroke"
      outlined
    ></line-chart>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-switch
        v-model="frequency"
        hide-details
        true-value="daily"
        false-value="monthly"
        :label="`Regroupement : ${
          frequency === 'daily' ? 'par jour' : 'par mois'
        }`"
      ></v-switch>
    </v-card-actions>
  </v-card>
</template>

<script>
import debounce from "lodash/debounce";

import LineChart from "@/components/common/charts/LineChart";

import useAxios from "@/hooks/useAxios";

import { colors } from "@/utils/constants";
import { getDaysArray, getMonthsArray } from "@/utils/dates";
import { formatNumber } from "@/utils/formatting";

const yAxis = (color = "") => ({
  min: 0,
  forceNiceScale: true,
  labels: {
    formatter: (val) => formatNumber(val),
    style: {
      fontSize: "12px",
      fontWeight: 400,
      ...(color ? { colors: color } : {}),
    },
  },
});

export default {
  name: "StatsByDateChart",
  components: {
    LineChart,
  },
  data() {
    return {
      loading: false,
      forceCall: false,
      frequency: "daily",
      colors: [colors.sessions, colors.chartDefault],
      series: [
        // Order must be the same than in yaxis definition here below
        {
          name: "sessions",
          data: [],
        },
        {
          name: "created",
          data: [],
        },
      ],
      yAxis: [
        // Order must be the same than in series definitions
        {
          seriesName: "sessions",
          title: {
            text: "Sessions",
            style: { color: colors.sessions },
          },
          ...yAxis(colors.sessions),
        },
        {
          opposite: true,
          seriesName: "created",
          title: {
            text: "Créés",
            style: { color: colors.chartDefault },
          },
          ...yAxis(colors.chartDefault),
        },
      ],
      xAxis: {
        categories: [],
        labels: {
          rotate: -45,
        },
        style: {
          fontSize: "12px",
          fontWeight: 400,
        },
      },
      stroke: {
        show: true,
        curve: "smooth",
        width: [2, 2],
      },
    };
  },
  setup() {
    const { axiosGet } = useAxios();

    return {
      axiosGet,
    };
  },
  methods: {
    debouncedGetData: debounce(async function () {
      await this.getData();
    }, 500),
    async getData() {
      if (
        (this.applyFilters || this.forceCall) &&
        !this.loading &&
        this.startDate &&
        this.startDate.length === 10 &&
        this.endDate &&
        this.endDate.length === 10
      ) {
        this.loading = true;
        const { data } = await this.axiosGet("/audience/stats-by-date-range", {
          start_date: this.startDate,
          end_date: this.endDate,
          sites: this.sites.join(","),
          categories: this.categories.join(","),
          period_type: this.periodType,
          frequency: this.frequency,
        });

        const categories =
          this.frequency === "monthly"
            ? getMonthsArray(this.startDate, this.endDate)
            : getDaysArray(this.startDate, this.endDate);
        this.xAxis = {
          ...this.xAxis,
          categories: categories,
        };

        this.series = [
          // Order must be the same than in chartOptions.yaxis definition
          {
            name: "sessions",
            type: "line",
            data: categories.map(
              (date) =>
                data.find((details) => details.date === date)?.sessions ?? 0
            ),
          },
          {
            name: "created",
            type: "column",
            data: categories.map(
              (date) =>
                data.find((details) => details.date === date)?.created ?? 0
            ),
          },
        ];
        this.loading = false;

        // Force clicking on button again to re-run that request :
        // (we don't call this dispatch earlier to be sure, as state is async, that all listening components could run their requests)
        this.$store.dispatch("common/updateApplyFilters", false);
      }

      this.forceCall = false;
    },
  },
  computed: {
    applyFilters() {
      return this.$store.getters["common/getApplyFilters"];
    },
    sites() {
      return this.$store.getters["audience/getSites"];
    },
    categories() {
      return this.$store.getters["audience/getCategories"];
    },
    startDate() {
      return this.$store.getters["audience/getDates"][0];
    },
    endDate() {
      return this.$store.getters["audience/getDates"][1];
    },
    periodType() {
      return this.$store.getters["audience/getPeriodType"];
    },
  },
  watch: {
    startDate() {
      this.debouncedGetData();
    },
    endDate() {
      this.debouncedGetData();
    },
    sites() {
      this.debouncedGetData();
    },
    categories() {
      this.debouncedGetData();
    },
    periodType() {
      this.debouncedGetData();
    },
    frequency() {
      this.forceCall = true;
      this.debouncedGetData();
    },
    applyFilters() {
      this.debouncedGetData();
    },
  },
};
</script>
