<template>
  <pie-chart
    title="Répartition des articles"
    subTitle="par sessions générées"
    :series="series"
    :labels="labels"
    :loading="loading"
    :formatter="formatter"
    outlined
  />
</template>

<script>
import debounce from "lodash/debounce";

import PieChart from "@/components/common/charts/PieChart.vue";

import useAxios from "@/hooks/useAxios";

import { formatNumber, formatPercent } from "@/utils/formatting";

export default {
  name: "StatsBySessionsChart",
  components: { PieChart },
  data() {
    return {
      loading: false,
      series: [],
      labels: [],
      nbArticlesTotal: 0,
      formatter: (val) => {
        return `${formatNumber(val)} (${formatPercent(
          val / this.nbArticlesTotal,
          1
        )})`;
      },
    };
  },
  setup() {
    const { axiosGet } = useAxios();

    return {
      axiosGet,
    };
  },
  methods: {
    debouncedGetData: debounce(async function () {
      await this.getData();
    }, 500),
    async getData() {
      if (
        this.applyFilters &&
        !this.loading &&
        this.startDate &&
        this.startDate.length === 10 &&
        this.endDate &&
        this.endDate.length === 10
      ) {
        this.loading = true;
        const { data } = await this.axiosGet(
          "/audience/stats-by-session-range",
          {
            start_date: this.startDate,
            end_date: this.endDate,
            period_type: this.periodType,
            sites: this.sites.join(","),
            categories: this.categories.join(","),
          }
        );

        this.series = Object.values(data).map((val) => val ?? 0);
        this.nbArticlesTotal = this.series.reduce((acc, val) => acc + val, 0);
        this.labels = this.nbArticlesTotal > 0 ? Object.keys(data) : [];

        this.loading = false;

        // Force clicking on button again to re-run that request :
        // (we don't call this dispatch earlier to be sure, as state is async, that all listening components could run their requests)
        this.$store.dispatch("common/updateApplyFilters", false);
      }
    },
  },
  computed: {
    applyFilters() {
      return this.$store.getters["common/getApplyFilters"];
    },
    sites() {
      return this.$store.getters["audience/getSites"];
    },
    categories() {
      return this.$store.getters["audience/getCategories"];
    },
    startDate() {
      return this.$store.getters["audience/getDates"][0];
    },
    endDate() {
      return this.$store.getters["audience/getDates"][1];
    },
    periodType() {
      return this.$store.getters["audience/getPeriodType"];
    },
  },
  watch: {
    startDate() {
      this.debouncedGetData();
    },
    endDate() {
      this.debouncedGetData();
    },
    periodType: function () {
      this.debouncedGetData();
    },
    sites() {
      this.debouncedGetData();
    },
    categories() {
      this.debouncedGetData();
    },
    applyFilters() {
      this.debouncedGetData();
    },
  },
};
</script>
